<template>
  <div class="body flex-row justify-center">
    <div class="submenu flex-row justify-center">
      <div class="head flex-row justify-center">
        <a-menu
          theme="light"
          mode="horizontal"
          v-model:selectedKeys="selectedKeys"
          class="menu"
        >
          <a-menu-item v-for="item in menu" :key="item.id">
            <router-link :to="item.path">{{ item.label }}</router-link>
          </a-menu-item>
        </a-menu>
      </div>
    </div>

    <div class="view">
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  data () {
    return {
      menu: [
        {
          id: 1,
          path: '/AddressBook/Department',
          label: '内部通讯录',
        },
        /* {
          id: 2,
          path: '/AddressBook/ExternaLinkMan',
          label: '外部通讯录',
        }, */ {
          id: 2,
          path: '/AddressBook/CompanyPartner',
          label: '企业通讯录',
        },
      ],
      selectedKeys: [],
    }
  },
  watch: {
    $route (to) {
      this.selectKey(to.path)
    },
  },
  created () {
    const path = this.$route.path
    this.selectKey(path)
  },
  methods: {
    selectKey (path) {
      const item = this.menu.find(function (item) {
        if (path === '/AddressBook/PostDuty') {
          item.path = path
        }
        return item.path === path
      })
      if (item) {
        const selectedKeys = []
        selectedKeys.push(item.id)
        this.selectedKeys = selectedKeys
      }
    },
  },
})

</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.body {
  width: 100%;
}
.head {
  border-top: 1px solid #d3d3d3;
  position: fixed;
  z-index: 10;
  width: 100%;
  background: white;
  top: 64px;
  left: 0;
}
.menu {
  width: 82%;
  height: 100%;
}
.view {
  width: 100%;
}
</style>
